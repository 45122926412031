import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';


import account_icon from '../assets/svg/user-icon.svg'
import list_icon from '../assets/svg/list-icon.svg'
import location_icon from '../assets/svg/location-icon.svg'
import heart_icon from '../assets/svg/heart.svg'
import key_icon from '../assets/svg/key-icon.svg'
import mail_icon from '../assets/svg/mail-icon.svg'
import logout_icon from '../assets/svg/logout-icon.svg'

import delete_icon from '../assets/svg/delete-icon.svg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

class AccountPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.selectClient = this.selectClient.bind(this);

        this.state = {
            clients: []
        };

    }
    selectClient(client) {
        this.props.socketIOClient.emit('updateUserData', {Client: client})
        this.setState({
            clients: []
        })
    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('updateUserData', (data) => {
            //console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
            }
        });

        this.props.socketIOClient.on('userLogin', (data) => {
            //console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
            }
        });
        this.props.socketIOClient.on('searchClients', (data) => {
            
            this.setState({
                clients: data
            })
    

        });





    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    login(data) {
        //console.log(data);
        this.props.socketIOClient.emit("userLogin", data);
    }




    render() {
        let product = this.state.product;

        return (
            <div className="account-wrap">

                {!this.props.uData ? <Redirect to='/login' ></Redirect> : null}
                

                <Container>
                    <Row>
                    <Col lg="3" className="d-none d-lg-block">
                    <div className="categories">

                                <h3>{this.props.translate('KATEGORIJE')}</h3>
                                <Categories {...this.props} selectCategory={(cat, level) => {
                                    let state = { page: 0 };
                                    state['_selectedCategory' + level] = cat._id;

                                    this.setState(state);

                                }}

                                    _selectedCategory0={this.state._selectedCategory0}
                                    _selectedCategory1={this.state._selectedCategory1}
                                    _selectedCategory2={this.state._selectedCategory2}
                                    _selectedCategory3={this.state._selectedCategory3}
                                    _selectedCategory4={this.state._selectedCategory4}


                                />




                            </div>
                        </Col>

                        <Col lg="9" className="content-wrap">
                            <Container>
                                <Row>
                                    <Col lg="12">
                                        <ul className="breadcrumb">
                                        <li><Link to='/account'>{this.props.translate('Nalog')}</Link></li>

                                        </ul>
                                    </Col>
                                    <Col lg="9">
                                        <h1>{this.props.uData && this.props.uData.User.Name}</h1>
                                    </Col>
                                    <Col lg="3">
                                        <button className="button button-right big-button" onClick={() => {this.props.setUserData(null); this.props.socketIOClient.emit('userLogout', {});} }> <Isvg src={logout_icon} /> {this.props.translate('ODJAVA')}</button>
                                    </Col>
                                    <Col lg="12">
                                        <Container className="box-container">
                                            <Row>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/edit'>
                                                        <div className="box">
                                                            <Isvg src={account_icon} />
                                                            <p>{this.props.translate('Upravljanje ličnim podacima')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/orders'>
                                                        <div className="box">
                                                            <Isvg src={list_icon} />
                                                            <p>{this.props.translate('Moje narudžbe')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/address'>
                                                        <div className="box">
                                                            <Isvg src={location_icon} />
                                                            <p>{this.props.translate('Adresa za dostavu')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/wishlist'>
                                                        <div className="box">
                                                            <Isvg src={heart_icon} />
                                                            <p>{this.props.translate('Lista omiljenih')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/change-password'>
                                                        <div className="box">
                                                            <Isvg src={key_icon} />
                                                            <p>{this.props.translate('Promenite lozinku')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                                <Col lg="4" xs="6">
                                                    <Link to='/account/newsletter'>
                                                        <div className="box">
                                                            <Isvg src={mail_icon} />
                                                            <p>{this.props.translate('Newsletter')}</p>
                                                        </div>
                                                    </Link>
                                                </Col>

                                            </Row>
                                        </Container>

                                            {this.props.uData && this.props.uData.IsSalesManager ?
                                    <div className=" delivery content delivery-no-shadow">
                                        <Row>
                                            <Col lg="12">
                                                <div className="input-wrap find-client-wrap">
                                                    <label>Pronađi klijenta</label>
                                                    <input class="form-control" onFocus={() => {
                                                        this.props.socketIOClient.emit('searchClients', {search: this.state.search});
                                                    }} type="text" value={this.state.search}  onChange={(e) => {

                                                        this.setState({
                                                            search: e.target.value
                                                        }, () => {
                                                            //console.log(this.state.search)
                                                            this.props.socketIOClient.emit('searchClients', {search: this.state.search});

                                                      
                                                        })


                                                    }} />
                                                    {this.state.clients.length ?
                                                        <ul ref={(node) => this.wrapperRef = node}>

                                                            {
                                                                this.state.clients.map((item, idx) => {
                                                                    return (
                                                                        <li onClick={() => this.selectClient(item)}>
                                                                            <Row>
                                                                                <Col lg="6" xs="6">
                                                                                    {item.User.Name}
                                                                                </Col>
                                                                                <Col lg="6" xs="6">
                                                                                    {item.User.EMail}
                                                                                </Col>

                                                                            </Row>
                                                                        </li>

                                                                    )
                                                                })
                                                            }
                                                        </ul>

                                                        :
                                                        null

                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        {this.props.uData && this.props.uData.Client ?
                                            <div className="deliveryBox-container">
                                                <div className="deliveryBox">
                                                    <h6>Klijent</h6>
                                                </div>
                                                <div className="text">
                                                    <Row>
                                                        <Col lg="6" md="6" xs="12">
                                                            <p>{this.props.uData.Client.BillingAddress.FirstName} {this.props.uData.Client.BillingAddress.LastName}</p>
                                                            <p>{this.props.uData.Client.BillingAddress.Street}</p>
                                                            <p>{this.props.uData.Client.BillingAddress.Zipcode} {this.props.uData.Client.BillingAddress.City}</p>
                                                            <br />
                                                            <p>{this.props.uData.Client.BillingAddress.EMail}</p>

                                                        </Col>
                                                        <Col lg="6" md="6" xs="12">
                                                            <div className="delete-client" onClick={() => this.selectClient(null)}>
                                                                <div className="delete-icon"><Isvg src={delete_icon} /></div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>

                                    : null

                                }



                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                  

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(AccountPage));
