import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';

import Text from './fields/text';
import Select from './fields/select';
import Check from './fields/check';

const required = value => value ? undefined : "Required"


const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    translate
}) => (

        <Check
            label={label}
            translate={translate}
            {...input}
        />
    )


const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
            children={children}
        />
    )

const registerForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    //console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>

            <h3>{props.translate('Podaci za prijavu')}</h3>

            <Field
                name="User.EMail"
                type="email"
                component={renderTextField}
                label={props.translate('E-Mail Adresa *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="password"
                type="password"
                component={renderTextField}
                label={props.translate('Lozinka *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="repeat_password"
                type="password"
                component={renderTextField}
                label={props.translate('Ponovi lozinku *')}
                placeholder=""
                validate={required}
            ></Field>
            <p>{props.translate('* Obavezna polja')}</p>

            <div className="spacer"></div>

            <h3>{props.translate('Adresa')}</h3>


            <Field
                name="BillingAddress.FirstName"
                type="text"
                component={renderTextField}
                label={props.translate('Ime *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="BillingAddress.LastName"
                type="text"
                component={renderTextField}
                label={props.translate('Prezime *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="BillingAddress.Street"
                type="text"
                component={renderTextField}
                label={props.translate('Ulica *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="BillingAddress.Zipcode"
                type="text"
                component={renderTextField}
                label={props.translate('Poštanski broj *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="BillingAddress.City"
                type="text"
                component={renderTextField}
                label={props.translate('Grad *')}
                placeholder=""
                validate={required}

            ></Field>

            <Field
                name="BillingAddress.Phone"
                type="text"
                component={renderTextField}
                label={props.translate('Telefon')}
                placeholder=""
            ></Field>



            <p>{props.translate('* Obavezna polja')}</p>

            <button type="submit" className="button">{props.translate('REGISTRUJ SE')}</button>


        </form>

    )
}

export default reduxForm({
    form: 'registerForm'  // a unique identifier for this form
})(registerForm)
