import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

export class BlogArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <Link to={`/blog/${this.props._id}`}>
                <article>
                    <img src={this.props.Image} />
                    <h6>{this.props.Title}</h6>
                    <p className="date">{moment.unix(this.props.time).format('DD.MM.YYYY HH:mm')}</p>
                    <p>{this.props.ShortDescription && this.props.ShortDescription.length > 100 ? this.props.ShortDescription.substring(0, 100) + '...' : this.props.ShortDescription}</p>
                </article>
            </Link>
        )
    }
}

export default BlogArticle;