import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';

import Footer from '../containers/footer';


import about1 from '../assets/images/about1.png';
import about2 from '../assets/images/about2.png';
import about3 from '../assets/images/about3.png';
import about4 from '../assets/images/about4.png';
import about5 from '../assets/images/about5.png';

import user1 from '../assets/images/user1.png';
import user2 from '../assets/images/user2.png';


import like_ico from '../assets/svg/like-badge.svg';
import phone_ico from '../assets/svg/phone-ico1.svg';
import Isvg from 'react-inlinesvg';

import Newsletter from '../components/newsletter';

import Map from '../components/map';
import yellow_stars from '../assets/svg/yellow-stars.svg';
import Slider from "react-slick";

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


class AboutUsPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            activeIndex: 0,
            banners: [
            ],
            verticalBanners: [

            ],
            slides: [

            ],
            categories: []

        };

    }



    componentDidMount() {
        window.scrollTo(0,0);
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchSlides', (data) => {
            //console.log(data);
            this.setState({
                slides: data
            })
        });

        this.props.socketIOClient.on('fetchBanners', (data) => {
            //console.log(data);
            let vertical = [];
            let horizontal = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].vertical) {
                    vertical.push(data[i]);
                } else {
                    horizontal.push(data[i]);
                }
            }
            this.setState({
                banners: horizontal,
                verticalBanners: vertical
            })
        });


        this.props.socketIOClient.on('fetchHomeProducts', (data) => {
            //console.log(data);
            this.setState({
                categories: data
            })
        });

        this.props.socketIOClient.emit("fetchHomeProducts", {});

        this.props.socketIOClient.emit("fetchSlides", {});

        this.props.socketIOClient.emit("fetchBanners", {});

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }



    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: window.innerWidth < 768 ? 2 : 4,
            slidesToScroll: 1
        };

        //console.log(this.props.categories);
        return (
            <div className="home-wrap">

                <section className="section page-top-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>O nama</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li>O nama</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section about-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <img src={about1} />
                            </Col>
                            <Col lg="6">
                                <h3>Ko smo mi?</h3>
                                <p>
                                    <strong>Master clean je porodična firma osnovana u aprilu 2011.godine,
kao Agencija za čišćenje stambenih i poslovnih prostora.</strong><br /><br />

                                    Mlad a u isto vrijeme iskusan i ambiciozan tim, od prvog dana
                                    poslovanja bilježi konstantan rast broja klijenata u cijeloj Bosni i
                                    Hercegovini, što je najbolji dokaz kvaliteta rada, ozbiljnog pristupa
                                    poslu i posvećenosti i razumijevanju potreba svakog klijenta
pojedinačno.<br /><br />

                                    Od 2015. godine proširujemo našu djelatnost na prodaju papirne
galanterije, sredstava i opreme za čišćenje, usisivača i drugih mašina za čišćenje renomiranih Evropskih proizvođača.<br /><br />

                                    I u tom segmentu iz dana u dan sve veći broj kupaca prepoznaje
                                    kvalitet naših roba, usluge redovne i brze dostave u cijeloj BiH i
                                    prepoznavanje stvarnih potreba naših kupaca.
                                </p>
                            </Col>

                            <Col lg="6">
                                <h3>Misija</h3>
                                <p>
                                    Naša misija je da svojom širokom  ponudom usluga i proizvoda iz
    prodajnog asortimana omogućimo našim klijentima besprekorno čiste prostore u kojima rade i borave kao i kupovinu vrhunske profesionalne opreme i sredstava za čišćenje  i potrošnog higijenskog materijala, sve na jednom mjestu i konstantno povećavamo kvalitet u svim segmentima našeg poslovanja.
                                </p>
                            </Col>
                            <Col lg="6">
                                <img src={about2} />
                            </Col>


                            <Col lg="6">
                                <img src={about3} />
                            </Col>
                            <Col lg="6">
                                <h3>Vizija</h3>
                                <p>
                                    Naša vizija je da budemo vodeća firma u BiH u segmentu usluga
    čišćenja i prodaje sredstava i opreme za ličnu i prostornu higijenu,
    stalno postavljajući više standarde i prateći i uvodeći nove trendove i inovacije na naše tržište.
                                </p>
                            </Col>


                        </Row>
                    </Container>
                </section>


                <section className="section service-info-section">
                    <Container>
                        <Row>
                            <Col lg="3">
                                <div className="block">
                                    <h6>Sve na jednom mjestu</h6>

                                    <p>Uskom saradnjom naših sektora za usluge čišćenja
i sektora prodaje,
u mogućnosti smo ponuditi kompletnu uslugu
od profesionalnog čišćenja do prodaje i isporuke
 sredstava i opreme za ličnu i prostornu higijenu.</p>
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="block">
                                    <h6>Individualan pristup</h6>

                                    <p>Svim korisnicima naših usluga i kupcima naših roba
pristupamo individualno sa posebnom pažnjom,
uočavajući njihove stvarne potrebe,
sa krajnjim ciljem pružanja ekonomičnog rješenja
uz zadržavanje maksimalnog kvaliteta.
</p>
                                </div>
                            </Col>

                            <Col lg="3">
                                <div className="block">
                                    <h6>Sve iz prve ruke</h6>

                                    <p>Ovlašteni smo uvoznik i distributer za BiH
više renomiranih brendova iz sektora
lične i prostorne higijene,hotelske kozmetike,
osvježivača prostora,dozirne tehnike za vešeraje,
perilice suđa,superkoncentrovana sredstva za čišćenje,...
</p>
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="block">
                                    <h6>Podrška nakon prodaje</h6>

                                    <p>Svim kupcima naših roba omogućili smo obuku njihovih
radnika za pravilno korištenje sredstava i opreme za rad,
dozirne tehnike kao i izradu plana čišćenja prostora ,
i servisnu podršku za dozirnu tehniku i mašine za pranje 
suđa i vešeraje.

</p>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>


               {/* <section className="section aboutus-info-section">
                    <Container>
                        <Row>
                            <Col lg="3">
                                <div className="blue-block">
                                    <Isvg src={like_ico} />
                                    <p>Garantujemo visoko kvalitetno čišćenje bilo kojih prostorija.</p>
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="block">
                                    <div className="progress">
                                        <div className="wrapper" data-anim="base wrapper">
                                            <div className="circle" data-anim="base left"></div>
                                            <div className="circle" data-anim="base right"></div>
                                        </div>
                                        <p>100%</p>
                                    </div>
                                    <h6>Zadovoljni korisnici</h6>
                                    <p>Qui euismod fabellas reformi dans ea, inermis ration ibus.</p>
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="block">
                                    <div className="progress">
                                        <div className="wrapper" data-anim="base wrapper">
                                            <div className="circle" data-anim="base left1"></div>
                                            <div className="circle" data-anim="base right"></div>
                                        </div>
                                        <p>96%</p>
                                    </div>
                                    <h6>Garancija klaviteta</h6>
                                    <p>Qui euismod fabellas reformi dans ea, inermis ration ibus.</p>
                                </div>
                            </Col>

                            <Col lg="3">
                                <div className="block">
                                    <div className="progress">
                                        <div className="wrapper" data-anim="base wrapper">
                                            <div className="circle" data-anim="base left2"></div>
                                            <div className="circle" data-anim="base right"></div>
                                        </div>
                                        <p>92%</p>
                                    </div>
                                    <h6>Eco-Friendly</h6>
                                    <p>Qui euismod fabellas reformi dans ea, inermis ration ibus.</p>
                                </div>
                            </Col>

                        </Row>
                    </Container>
        </section>*/}



                <section className="section aboutus-info-section-1">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h3>Inovativna <span>rješenja </span></h3>
                                <p>Vršimo stalnu aktivnost istraživanja potreba naših kupaca
i pratimo dešavanja na svjetskom tržištu,
kako bi našim klijentima uvijek mogli ponuditi
inovativna,efikasna i ekonomična rješenja.
</p>
                                <div className="wrap">
                                    <img src={about4} />
                                    <div className="block">
                                        <div className="icon">
                                            <Isvg src={phone_ico} />
                                        </div>
                                        <p>Naše preduzeće<br /> čeka na vaše pozive.</p>
                                        <a className="tel" href="tel://+387 65 400 805">+387 65 400 805</a><br/>
                                        <a className="tel" href="tel://+387 66 177 300">+387 66 177 300</a>
                                        <Link to='/contact'><button className="button">Kontaktirajte nas</button></Link>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6">
                                <img src={about5} />
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-users">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <Isvg src={yellow_stars} />
                                <h2>Naš tim</h2>
                                <p>Predstavljamo Vam naš tim</p>
                            </Col>
                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    <div className="user">
                                        <h6>Slavica Vasić</h6>
                                        <p>Vlasnik</p>
                                        <a href="mailto://slavica.vasic@masterclean.ba">slavica.vasic@masterclean.ba</a>
                                    </div>
                                    <div className="user">
                                        <h6>Vitomir Vasić </h6>
                                        <p>Izvršni direktor </p>
                                        <a href="mailto://vitomir.vasic@masterclean.ba">vitomir.vasic@masterclean.ba</a>
                                    </div>

                                    <div className="user">
                                        <h6>Daliborka Šarčević</h6>
                                        <p>Operativni direktor</p>
                                        <a href="mailto://office@masterclean.ba">office@masterclean.ba</a>
                                    </div>

                                    <div className="user">
                                        <h6>Srđan Vasić</h6>
                                        <p>Marketing menadžer</p>
                                        <a href="mailto://marketing@masterclean.ba">marketing@masterclean.ba</a>
                                    </div>




                                </Slider>

                            </Col>

                        </Row>
                    </Container>
                </section>


                <Newsletter {...this.props} />

                <Map {...this.props} />
                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(AboutUsPage));
