import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';

import Footer from '../containers/footer';


import service from '../assets/images/service.png';
import about2 from '../assets/images/about2.png';
import about3 from '../assets/images/about3.png';
import about4 from '../assets/images/about4.png';
import about5 from '../assets/images/about5.png';

import badge1 from '../assets/svg/service-badge-1.svg';
import badge2 from '../assets/svg/service-badge-2.svg';
import badge3 from '../assets/svg/service-badge-3.svg';


import like_ico from '../assets/svg/like-badge.svg';
import phone_ico from '../assets/svg/phone-ico1.svg';
import Isvg from 'react-inlinesvg';

import Newsletter from '../components/newsletter';

import Map from '../components/map';
import yellow_stars from '../assets/svg/yellow-stars.svg';
import Slider from "react-slick";

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


class ServicePage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            activeIndex: 0,
            banners: [
            ],
            verticalBanners: [

            ],
            slides: [

            ],
            categories: []

        };

    }



    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchSlides', (data) => {
            //console.log(data);
            this.setState({
                slides: data
            })
        });

        this.props.socketIOClient.on('fetchBanners', (data) => {
            //console.log(data);
            let vertical = [];
            let horizontal = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].vertical) {
                    vertical.push(data[i]);
                } else {
                    horizontal.push(data[i]);
                }
            }
            this.setState({
                banners: horizontal,
                verticalBanners: vertical
            })
        });


        this.props.socketIOClient.on('fetchHomeProducts', (data) => {
            //console.log(data);
            this.setState({
                categories: data
            })
        });

        this.props.socketIOClient.emit("fetchHomeProducts", {});

        this.props.socketIOClient.emit("fetchSlides", {});

        this.props.socketIOClient.emit("fetchBanners", {});

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }



    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: window.innerWidth < 768 ? 2 : 4,
            slidesToScroll: 1
        };

        //console.log(this.props.categories);
        return (
            <div className="home-wrap">

                <section className="section page-top-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>Usluge čišćenja</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li>Usluge čišćenja</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section about-section service-section">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <p>
                                USLUGE ČIŠĆENJA VEĆ DUGO VREMENA NISU LUKSUZ VEĆ STVARNA POTREBA DA SVOJE VRIJEME POSVETIMO NAŠIM  POSLOVNIM OBAVEZAMA ALI I NAKON POSLA KVALITETNOM VREMENU PROVEDENOM SA NAMA DRAGIM OSOBAMA U ČISTOM I PRIJATNOM OKRUŽENJU.
                                </p>
                            </Col>
                            <Col lg="6">
                                <img src={service} />
                            </Col>

                            <Col lg="6" className="padding-text">
                                <p>
                               <strong> Čistoća Vašeg poslovnog prostora od presudnog je značaja 
za Vaše saradnike, kupce i goste.</strong><br/><br/>

Kao firma sa velikim iskustvom našeg rukovodećeg kadra i 
vlastitim iskustvom u čišćenju poslovnih i stambenih prostora 
kojim se bavimo od osnivanja firme 2011. Godine, kroz stotine 
projekata i stotine hiljada metara kvadratnih očišćenih 
prostora, od industrijskih hala, hotela, termoelektrana,raznih 
poslovnih prostora i objekata do kuća i stanova, stekli smo 
veliko iskustvo i samopouzdanje u svom radu.
                                </p>
                            </Col>


                        </Row>
                    </Container>
                </section>

                <section className="section service-info-section">
                    <Container>
                        <Row>
                            <Col lg="4">
                                <div className="block">
                                    <Isvg src={badge1} />
                                    <p>Mi Vam nudimo usluge redovnog svakodnevnog, periodičnog ili jednostavno čišćenja po potrebi kad to Vama treba, bez obzira na veličinu i namjenu objekta koji se čisti.</p>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="block">
                                <Isvg src={badge2} />
                                    <p>Sa preko 100 radnika čiji se broj konstatno povećava, usluge redovnog čišćenja pružamo na području cijele Bosne i Hercegovine.</p>
                                </div>
                            </Col>

                            <Col lg="4">
                                <div className="block">
                                <Isvg src={badge3} />
                                    <p>Cijena naših usluga čišćenja ili održavanja higijene u Vašem objektu se određuje individualno nakon našeg detaljnog pregleda Vašeg objekta i zajedničkog usaglašavanja.</p>
                                </div>
                            </Col>

                            <Col lg="12" className="blue-wrap">
                            <p>UKOLIKO IMATE POTREBU ZA REDOVNIM ILI JEDNOKRATNIM ČIŠĆENJEM VAŠEG POSLOVNOG ILI PRIVATNOG PROSTORA, KONTAKTIRAJTE NAS KROZ NAŠU FORMU I NAŠI SARADNICI ĆE VAS KONTAKTIRATI U MAKSIMALNO KRATKOM ROKU DA SE DOGOVORIMO ZA BESPLATNU PROCJENU ČIŠĆENJA.</p>
                            </Col>

                        </Row>
                    </Container>
                </section>

              


                <section className="section aboutus-info-section-1">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h3>Inovativna <span>rješenja </span></h3>
                                <p>Vršimo stalnu aktivnost istraživanja potreba naših kupaca
i pratimo dešavanja na svjetskom tržištu,
kako bi našim klijentima uvijek mogli ponuditi
inovativna,efikasna i ekonomična rješenja.
</p>
                                <div className="wrap">
                                    <img src={about4} />
                                    <div className="block">
                                        <div className="icon">
                                            <Isvg src={phone_ico} />
                                        </div>
                                        <p>Naše preduzeće<br /> čeka na vaše pozive.</p>
                                        <a className="tel" href="tel://+387 65 400 805">+387 65 400 805</a><br/>
                                        <a className="tel" href="tel://+387 66 177 300">+387 66 177 300</a>
                                        <Link to='/contact'><button className="button">Kontaktirajte nas</button></Link>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6">
                                <img src={about5} />
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-brands">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <Isvg src={yellow_stars} />
                                <h2>Naše reference</h2>
                                <p>Referenc lista kominteneta za usluge čišćenja</p>
                            </Col>
                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                {
                                    this.props.companies.map((item, idx) => {
                                        return (
                                            <div className="brand">
                                            <a href={item.Link} target="_blank">
                                                <img src={item.Image} />
                                            </a>
                                        </div>
    
                                        )
                                    })
                                }


                                </Slider>

                            </Col>

                            <Col lg="12" className="btn-wrap">
                                <Link to='/reference'> <button className="button">Detaljnije</button></Link>
                            </Col>

                        </Row>
                    </Container>
                </section>


                <Newsletter {...this.props} />

                <Map {...this.props} />
                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(ServicePage));
