import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';

import Text from './fields/text';
import Select from './fields/select';
import Check from './fields/check';

const required = value => value ? undefined : "Required"


const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    translate
}) => (

        <Check
            label={label}
            translate={translate}
            {...input}
        />
    )


const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderSelectField = ({
    input,
    placeholder,
    label,
    meta: { touched, error },
    children,
}) => (

        <Select
            placeholder={placeholder}
            label={label}
            errorText={touched && error}
            error={touched && error}
            {...input}
            children={children}
        />
    )

const newsletterForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    //console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>

        
            <button type="submit" className="button">{props.translate('PRIJAVI SE')}</button>             <button type="submit" onClick={(e) => {e.preventDefault(); props.onSubmit({SubscribeToNewsletter: false})}} name="SubscribeToNewsletter" className="button button-black">{props.translate('ODJAVI SE')}</button>



        </form>

    )
}

export default reduxForm({
    form: 'newsletterForm'  // a unique identifier for this form
})(newsletterForm)
