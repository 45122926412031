import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

export class Categories extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    _renderCategories(items, level) {
        return (
            <ul>
                {
                    items.map((item, idx) => {
                        return (
                            <li key={idx} className={this.props['_selectedCategory' + level] === item._id ? "active" : null} onClick={() => {
                                this.props.selectCategory(item, level);

                            }}><Link to={'/category' + item.Breadcrumb}> {item.Icon ? <Isvg src={item.Icon} /> : null } {item.Name && item.Name}</Link>
                                {this.props['_selectedCategory' + level] === item._id && item.subcategories && item.subcategories.length ? this._renderCategories(item.subcategories, level + 1) : null}
                            </li>
                        )
                    })
                }
            </ul>
        )
    }


    render() {
        return (

            this._renderCategories(this.props.categories, 0)

        )
    }
}

export default Categories;