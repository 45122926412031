import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';

import Text from './fields/text';
import Textarea from './fields/textarea';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


const required = value => value ? undefined : "Required"

const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )

const renderTextAreaField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Textarea
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            error={touched && error}

            {...input}
        />
    )


const contactForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    //console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>
                <Row>
                    <Col lg="6">
                        <Field
                            name="FirstName"
                            type="text"
                            component={renderTextField}
                            placeholder="Ime"
                            validate={required}

                        ></Field>
                    </Col>
                    <Col lg="6">

                        <Field
                            name="LastName"
                            type="text"
                            component={renderTextField}
                            placeholder="Prezime"
                            validate={required}

                        ></Field>
                    </Col>
                    <Col lg="12">

                        <Field
                            name="EMail"
                            type="email"
                            component={renderTextField}
                            placeholder="E-mail adresa"
                            validate={required}

                        ></Field>
                    </Col>
                    <Col lg="12">

                        <Field
                            name="Message"
                            type="text"
                            component={renderTextAreaField}
                            placeholder="Vaša poruka"
                            validate={required}

                        ></Field>
                    </Col>





                    <Col lg="6">

                        <button type="submit" className="button">{props.translate('Pošalji')}</button>
                    </Col>

                </Row>
        </form>

    )
}

export default reduxForm({
    form: 'contactForm'  // a unique identifier for this form
})(contactForm)
