import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import Map from '../components/map';
import Footer from '../containers/footer';


import Newsletter from '../components/newsletter';
import phone from '../assets/svg/contact-phone.svg';
import mail from '../assets/svg/contact-mail.svg';
import location from '../assets/svg/contact-location.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

import ContactForm from '../components/forms/contactForm';

class ContactPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.contact = this.contact.bind(this);

        this.state = {

        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('contact', (data) => {
            //console.log(data);
            this.setState({
                _done: true
            })
        });



    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    contact(data) {
        //console.log(data);

        this.props.socketIOClient.emit("contact", data);
    }




    render() {
        let product = this.state.product;

        return (

            <div className="home-wrap">

                <section className="section page-top-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>Kontakt</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li>Kontakt</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section contact-section">
                    <Container>
                        <Row>
                            <Col lg="5" className="left">
                                <h3>Tu smo za Vas</h3>
                                <p>Stojimo Vam na raspolaganju za sva pitanja.
        Možete nas kontaktirati putem telefona ili E-maila
        ili popunite formu desno.
Odgovorićemo Vam u što kraćem roku.</p>
                                <div className="spacer"></div>
                                <h6>Kontakt informacije</h6>
                                <div className="item">
                                    <Isvg src={phone} />
                                    <div>
                                        <p>Telefon</p>
                                        <p>+387 65 400 805</p>
                                    </div>
                                </div>
                                <div className="item">
                                    <Isvg src={mail} />
                                    <div>
                                        <p>E-mail</p>
                                        <p>info@masterclean.ba</p>
                                    </div>
                                </div>
                                <div className="spacer"></div>
                                <h6>Lokacija</h6>
                                <div className="item">
                                    <Isvg src={phone} />
                                    <div>
                                        <p>Adresa</p>
                                        <p>Veljka Milankovića 1, 78430 Prnjavor</p>
                                    </div>
                                </div>


                            </Col>
                            <Col lg="7" className="right">
                                <h3>Kontaktirajte nas</h3>
                                <ContactForm {...this.props} />
                            </Col>

                            <Col lg="12">
                            <Map {...this.props} />

                            </Col>
                        </Row>
                    </Container>
                </section>




                <Newsletter {...this.props} />

                <Footer {...this.props} />



            </div >


        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(ContactPage));
