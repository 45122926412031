import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';

export class BlogArticle extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        console.log(this.props)
        return (

            <Link to={`/blog/${this.props._id}`}>

                <article className={this.props.index % 2 == 0 ? 'left-image': ''}>
                    <Container>
                        { this.props.index % 2 == 0 ?
                        <Row>
                            <Col lg="6" className="image">
                                <img src={this.props.Image} />
                            </Col>
                            <Col lg="6" className="content">
                                <h6>{this.props.Title}</h6>
                                <p className="date">{moment.unix(this.props.time).format('DD.MM.YYYY HH:mm')}</p>
                                <p>{this.props.ShortDescription}</p>
                                <Link to={`/blog/${this.props._id}`}>{this.props.translate('DETALJNIJE')}</Link>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col lg="6" className="content">
                                <h6>{this.props.Title}</h6>
                                <p className="date">{moment.unix(this.props.time).format('DD.MM.YYYY HH:mm')}</p>
                                <p>{this.props.ShortDescription}</p>
                                <Link to={`/blog/${this.props._id}`}>{this.props.translate('DETALJNIJE')}</Link>
                            </Col>
                            <Col lg="6" className="image">
                                <img src={this.props.Image} />
                            </Col>

                        </Row>

                        }
                    </Container>
                </article>
            </Link>
        )
    }
}

export default BlogArticle;