import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import BlogArticle from '../components/blogArticle';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';
import yellow_stars from '../assets/svg/yellow-stars.svg';

import Map from '../components/map';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


class CompaniesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }

    componentDidMount(){
        window.scrollTo(0,0);
    }


    render() {

        return (
            <div className="home-wrap">

                <section className="section page-top-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>Reference</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li>Reference</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section className="section section-brands">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <Isvg src={yellow_stars} />
                                <h2>Naše reference</h2>
                                <p>Referenc lista kominteneta za usluge čišćenja</p>
                            </Col>

                            {
                                this.props.companies.map((item, idx) => {
                                    return (
                                        <Col lg="3" xs="6" >
                                            <div className="brand">
                                                <a href={item.Link} target="_blank">
                                                    <img src={item.Image} />
                                                </a>
                                            </div>
                                        </Col>

                                    )
                                })
                            }





                        </Row>
                    </Container>
                </section>

                <Newsletter {...this.props} />
                <Map {...this.props}></Map>

                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(CompaniesPage));
