import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import info_icon from '../assets/svg/info-icon.svg';
import BlogArticle from '../components/blogArticle1';

import moment from 'moment';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';


class NewsDetailPage extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }



    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }


    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.setState({
                product: {},
            }, () => {
                this.props.socketIOClient.emit('fetchNewsItem', { _id: this.props[0].match.params.id });
            });
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchNewsItem', (data) => {
            //console.log(data);
            this.setState(
                data
            );
        });




        this.props.socketIOClient.emit('fetchNewsItem', { _id: this.props[0].match.params.id });


    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchNewsItem");
    }




    render() {
        let product = this.state.product;

        return (
            <div className="home-wrap">


                <section className="section page-top-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>Novosti</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li>Novosti</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Container>
                    <Row>

                        <Col lg="9" className="content-wrap news-detail">
                                        <img src={this.state.Image} />

                                        <h1>{this.state.Title}</h1>
                                        <p className="date">{moment.unix(this.state.time).format('DD.MM.YYYY HH:mm')}</p>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.Content }}>
                                        </div>


                        </Col>
                        <Col lg="3" className="d-none d-lg-block latest-news">
                        <h3>Novosti u Master clean-u</h3>
                    {
                                this.props.news.map((item, idx) => {
                                    if (idx < 3)
                                        return (
                                                <BlogArticle {...item} translate={this.props.translate}></BlogArticle>
                                        )
                                })
                            }

                        </Col>

                    </Row>
                </Container>


                <Newsletter {...this.props} />


                <Footer {...this.props} />



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(NewsDetailPage));
