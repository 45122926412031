import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import Isvg from 'react-inlinesvg';
import icon from '../assets/svg/newsletter.svg';

export class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.subscribe = this.subscribe.bind(this);

        this.state = {
            email: ''
        };

    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('subscribeToNewsletter', (data) => {
            //console.log(data);
            this.setState({
                _done: true,
                email: ''
            })
        });



    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("subscribeToNewsletter");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }


    subscribe() {

        this.props.socketIOClient.emit("subscribeToNewsletter", { email: this.state.email });
    }


    render() {
        if (process.env.REACT_APP_IS_APP){
            return null;
        }

        return (
            <section className="section section-newsletter">
                <Container>
                    <Row>
                        <Col lg="1"><Isvg src={icon} /></Col>
                        <Col lg="2"><h3>Newsletter</h3></Col>
                        <Col lg="2"><p>Prijavite se na naš Newsletter i primajte najnovije ponude</p></Col>

                        {!this.state._done ?

                            <Col lg={{ size: 7 }}>
                                <input type="text" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} placeholder="Unesite Vašu E-mail adresu" />
                                <button className="button" onClick={this.subscribe}>PRIJAVI SE</button>




                            </Col>
                            :
                            <Col lg={{ size: 7 }}>

                                <p className="text-center text-white">{this.props.translate('Uspješno ste se pretplati na naš newsletter.')}</p>



                            </Col>
                        }
                    </Row>
                </Container>
            </section>
        )
    }
}

export default Newsletter;