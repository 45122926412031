import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom';

import Text from './fields/text';



const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error },
}) => (

        <Text
            placeholder={placeholder}
            label={label}
            type={type}
            errorText={touched && error}
            {...input}
        />
    )


const changePasswordForm = (props) => {
    const { handleSubmit, pristine, reset, submitting } = props;
    //console.log(pristine, submitting);

    return (
        <form onSubmit={handleSubmit}>

            

             <Field
                name="password"
                type="password"
                component={renderTextField}
                label={props.translate('Trenutna lozinka')}
                placeholder=""
            ></Field>

            <div className="spacer"></div>

             <Field
                name="new_password"
                type="password"
                component={renderTextField}
                label={props.translate('Nova lozinka')}
                placeholder=""
            ></Field>

             <Field
                name="repeat_password"
                type="password"
                component={renderTextField}
                label={props.translate('Ponovite lozinku')}
                placeholder=""
            ></Field>


            <button type="submit" className="button">{props.translate('SPREMI')}</button>


        </form>

    )
}

export default reduxForm({
    form: 'changePasswordForm'  // a unique identifier for this form
})(changePasswordForm)
