import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';

import Footer from '../containers/footer';


import gallery1 from '../assets/images/gallery1.png';
import gallery2 from '../assets/images/gallery2.png';
import gallery3 from '../assets/images/gallery3.png';
import gallery4 from '../assets/images/gallery4.png';

import user1 from '../assets/images/user1.png';
import user2 from '../assets/images/user2.png';


import like_ico from '../assets/svg/like-badge.svg';
import phone_ico from '../assets/svg/phone-ico1.svg';
import Isvg from 'react-inlinesvg';

import Newsletter from '../components/newsletter';

import Map from '../components/map';
import yellow_stars from '../assets/svg/yellow-stars.svg';
import Slider from "react-slick";
import close_ico from '../assets/svg/close-ico.svg';

import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselControl,
    CarouselItem,

} from 'reactstrap';


class GalleryPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            activeIndex: 0,
            gallery: []

        };

    }



    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchGallery', (data) => {
            this.setState({
                gallery: data
            })
        });




        this.props.socketIOClient.emit("fetchGallery", {});

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;

    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {


        let arr = [];

        for (let i = 0; i < this.state.gallery.length; i++) {
            arr.push([this.state.gallery[i]])

            let array = [];

            for (let j = i + 1; j < i + 4; j++) {
                array.push(this.state.gallery[j]);
            }
            arr.push(array);
            array = [];
            i = i + 3;
            for (let j = i + 1; j < i + 4; j++) {
                array.push(this.state.gallery[j]);
            }
            arr.push(array);
            i = i + 4;
            arr.push([this.state.gallery[i]])
        }
        const { activeIndex } = this.state;

        const slides = this.state.gallery && this.state.gallery.map((item) => {
            return (
                <CarouselItem
                    tag="div"
                    key={item}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div className="lightbox-item">
                        <img src={item} />
                    </div>

                </CarouselItem>
            );
        });

        return (
            <div className="home-wrap">

                <section className="section page-top-section">
                    <Container>
                        <Row>
                            <Col lg="6">
                                <h1>Foto galerija</h1>
                            </Col>
                            <Col lg="6">
                                <ul>
                                    <li><Link to='/'>Početna</Link></li>
                                    <li>Foto galerija</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </section>


                <section className="section section-gallery">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <Isvg src={yellow_stars} />
                                <h2>Naše reference</h2>
                                <p>Fotografie sa terena</p>
                            </Col>

                            {
                                arr.map((item, idx) => {
                                    if (item.length == 1 && item[0]) {
                                        return (
                                            <Col lg="6">
                                                <img src={item[0]} onClick={() => {
                                                    let index = 0;
                                                    for (let i = 0; i < idx; i++) {
                                                        index += arr[i].length;
                                                    }

                                                    this.setState({ lightbox: true, activeIndex: index })
                                                }} />
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <Col lg="6" className="no-padding">
                                                <Container>
                                                    <Row>
                                                        {
                                                            item.map((sub, sidx) => {
                                                                return (
                                                                    <Col lg={sidx == 0 || sidx == 1 ? 6 : 12}>
                                                                        <img src={sub} onClick={() => {
                                                                            let index = 0;
                                                                            for (let i = 0; i < idx; i++) {
                                                                                index += arr[i].length;
                                                                            }
                                                                            index += sidx;


                                                                            this.setState({ lightbox: true, activeIndex: index })
                                                                        }} />
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                </Container>
                                            </Col>

                                        )
                                    }
                                })
                            }

                        </Row>
                    </Container>
                </section>


                <Newsletter {...this.props} />

                <Map {...this.props} />
                <Footer {...this.props} />
                {this.state.lightbox ?
                    <div className="lightbox">
                        <div className="close" onClick={() => this.setState({ lightbox: null })}>
                            <Isvg src={close_ico}  />
                        </div>
                        <Carousel
                            activeIndex={activeIndex}
                            next={this.next}
                            previous={this.previous}
                            autoPlay={null}
                        >
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                        </Carousel>

                    </div>
                    : null
                }



            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(GalleryPage));
