import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';
import Isvg from 'react-inlinesvg';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';

import article1 from '../assets/images/article1.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import Categories from '../components/categories';

import star_icon from '../assets/svg/star-icon.svg';
import plus_icon from '../assets/svg/plus-icon.svg';
import minus_icon from '../assets/svg/minus-icon.svg';

import wishlistIcon from '../assets/svg/wishlist-icon.svg';

import image from '../assets/images/no-image.jpg';
import Slider from "react-slick";
import banner4 from '../assets/images/banner4.png';
import banner5 from '../assets/images/banner5.png';
import banner6 from '../assets/images/banner6.png';
import logo from '../assets/svg/logo.svg';
import close_ico from '../assets/svg/close-ico.svg';
import phone_ico from '../assets/svg/phone-ico1.svg';
import yellow_stars from '../assets/svg/yellow-stars.svg';


import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption

} from 'reactstrap';


class AppDetailPage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.addToWishlist = this.addToWishlist.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.addToCartAndBuy = this.addToCartAndBuy.bind(this);

        this.state = {
            attributes: [],
            variations: [],
            product: { Images: [] },
            banners: [],
            similarProducts: [],
            activeIndex: 0,
            _loading: true

        };

    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.product.Images.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.product.Images.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }


    generateBreadcrumb(items, breadcrumb, level) {
        if (breadcrumb[0] == "/") {
            breadcrumb = breadcrumb.substr(1);
        }
        let broken = breadcrumb.split("/");
        let arr = [];

        if (level >= broken.length) {
            return [];
        }

        for (let i = 0; i < items.length; i++) {
            //console.log(broken, items[i], level);
            if (broken[level] == items[i].Alias) {

                arr.push({
                    _id: items[i]._id,
                    name: items[i].Name,
                    link: "/category/" + items[i].Alias
                });

                if (items[i].subcategories && items[i].subcategories.length) {
                    arr = arr.concat(this.generateBreadcrumb(items[i].subcategories, breadcrumb, level + 1));
                }

                return arr;
            }
        }

    }
    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        ////console.log(this.props[0]);
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.setState({
                product: {
                    Images: [],
                    _loading: true
                },
            }, () => {
                this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });
            });
        }

        if (!this.props.breadcrumb.length && this.props.categories.length && this.state.product && this.state.product._id && this.state.product.Breadcrumb && !this.state._updatingBreadcrumb) {
            let breadcrumb = this.generateBreadcrumb(this.props.categories, this.state.product.Breadcrumb, 0);


            this.setState({
                _updatingBreadcrumb: true
            })


            if (breadcrumb) {
                let state = {};

                for (let i = 0; i < breadcrumb.length; i++) {
                    state['_selectedCategory' + i] = breadcrumb[i]._id;
                }
                this.setState(state);
            }

            //console.log(breadcrumb);
            this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);
        }

    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchProduct', (data) => {
            //console.log(data);
            this.setState({
                product: data,
                previewImage: data.YouTubeVideo && data.YouTubeVideoPosition == 0 ? 'youtube-video' : (data.Images && data.Images.length && data.Images[0]),
                package: data.package && data.package[0],
                quantity: data.MinOrder,
                _loading: null
            });


            this.props.socketIOClient.emit('fetchSimilarProducts', { Breadcrumb: data.Breadcrumb, productId: data._id });


        });


        this.props.socketIOClient.on('addToCart', (data) => {
            //console.log(data);
            if (data.successful) {
                this.props.showInfoMessage(this.props.translate('Artikal je uspješno dodat u korpu.'));
                this.props.socketIOClient.emit('cartInfo', {});
                if (this.state._buyNow) {
                    this.props[0].history.push('/cart');
                }
            } else {
                this.props.showInfoMessage(this.props.translate('Nema dovoljno na stanju.'), true);

            }
        });

        this.props.socketIOClient.on('addToWishlist', (data) => {
            //console.log(data);
            this.props.showInfoMessage(this.props.translate('Artikal je dodat u listu omiljenih.'));

        });

        this.props.socketIOClient.on('fetchBanners', (data) => {
            //console.log(data);
            let vertical = [];
            let horizontal = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].vertical) {
                    vertical.push(data[i]);
                } else {
                    horizontal.push(data[i]);
                }
            }
            this.setState({
                banners: horizontal,
            })
        });


        this.props.socketIOClient.on('fetchSimilarProducts', (data) => {
            //console.log(data);
            this.setState({
                similarProducts: data
            })
        });


        this.props.socketIOClient.emit("fetchBanners", {});


        this.setState({
            _loading: true
        }, () => {
            this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });
        })


    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchProduct");
        this.props.socketIOClient.removeAllListeners("addToWishlist");
        this.props.socketIOClient.removeAllListeners("addToCart");

    }



    addToWishlist() {
        if (!this.props.uData) {
            this.props[0].history.push('/login');
            return;
        }

        this.props.socketIOClient.emit('addToWishlist', { _id: this.props[0].match.params.id });
    }


    addToCart() {
        this.props.socketIOClient.emit('addToCart', { _id: this.props[0].match.params.id, quantity: parseInt(this.state.quantity), package: this.state.package });

    }
    addToCartAndBuy() {
        this.props.socketIOClient.emit('addToCart', { _id: this.props[0].match.params.id, quantity: parseInt(this.state.quantity), package: this.state.package });
        this.setState({
            _buyNow: true
        })
    }


    onTouchStart(event) {
        var x = event.clientX;
        var y = event.clientY;
        if (!this.state._startSwipePos) {
            this.setState({
                _startSwipePos: x,
                _startSwipePosY: y,
                _startLeft: this.carousel.scrollLeft
            });
        }
    }

    onTouchEnd() {
        this.setState({
            _startSwipePos: null,
            _startSwipePosY: null,
            _startLeft: null
        });
    }

    onTouchMove(event) {
        var x = event.clientX;
        var y = event.clientY;

        if (this.state._startSwipePos) {
            this.carousel.scrollLeft = this.state._startLeft - (x - this.state._startSwipePos);
        }

        this.setState({
            _swipePos: x
        });


    }


    render() {
        let product = this.state.product;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const { activeIndex } = this.state;

        const slides = product.Images.map((item) => {
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item}
                >
                    <img src={item && item.scaleImage(400)} />
                </CarouselItem>
            );
        });

        //console.log(this.imagesNode)
        return (
            <div className="app-detail-wrap">

                <Container>
                    <Row>
                        <Col lg="12" className="content-wrap">
                            <Container className="article-box">

                                <Row>
                                    <Col lg="5" className="article-image slider-container">
                                        <Slider {...settings}>
                                            {
                                                product.Images.reverse().map((item, idx) => {
                                                    return (
                                                        <img src={item && item.scaleImage(400)} />
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </Col>
                                    <Col lg="7">


                                        <h2>{product.Name && product.Name}</h2>

                                        {/*product.StockLevel > 0 ?
                                                        <p className="stock"> <div className="in-stock"></div> Artikal je dostupan</p>
                                                        :
                                                        product.StockLevel === 0 ?
                                                            <p className="stock"> <div className="out-of-stock"></div> Artikal nije dostupan</p>
                                                            :
                                                            null
                                                    */}

                                        <div className="price">
                                            <span>{product.price ? product.price.formatPrice(this.props.currency) : 'Cijena na upit'}</span>
                                        </div>

                                        <Container className="short-description-container">
                                            <Row>
                                                <Col xs="9">
                                                    <p className="short-description" dangerouslySetInnerHTML={{ __html: product.ShortDescription && product.ShortDescription }}>

                                                    </p>
                                                </Col>

                                                <Col xs="3">
                                                    <button className="wishlist-btn" onClick={this.addToWishlist}><Isvg src={wishlistIcon} /> </button>

                                                </Col>
                                            </Row>
                                        </Container>






                                    </Col>

                                </Row>
                            </Container>

                            <Container className="article-box">
                                <Row>
                                    <Col lg="12" className="about-product-title">
                                        <div >
                                            <h6>O proizvodu</h6>
                                        </div>
                                    </Col>
                                    <Col dangerouslySetInnerHTML={{ __html: product.Description && product.Description }} lg="12" className="product-description">
                                    </Col>

                                    <Col lg="12">
                                        <a href={product.Document}><button className="button download-button">PREUZMI KOMPLETNU SPECIFIKACIJU</button></a>
                                    </Col>
                                </Row>
                            </Container>



                        </Col>

                        <Col lg="12">
                        </Col>


                    </Row>
                </Container>


                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <Isvg src={yellow_stars} />
                                <h2>Izdvojeno iz proizvoda</h2>
                                <p>Za Vas smo izdvojili posebnu ponudu proizvoda.</p>
                            </Col>

                            {
                                this.props.promotedProducts.map((item, idx) => {
                                    return (
                                        <Col lg="3" xs="6">
                                            <Article
                                                _id={item._id}
                                                alias={item.Alias}
                                                image={item.Images && item.Images.length && item.Images[0]}
                                                title={item.Name}
                                                package={item.package}
                                                currency={this.props.currency}
                                                discount={item.discount}
                                                price={item.price ? item.price : 0}
                                                inStock={item.StockLevel > 0 ? true : false}
                                                lang={this.props.lang}
                                                translate={this.props.translate}>
                                            </Article>
                                        </Col>
                                    )
                                })
                            }



                        </Row>
                    </Container>
                </section>



                                        {product.StockLevel > 0 && product.price ?
                                            <div className="mobile-buttons">
                                                <div className="quantity-btn">
                                                    <div className="detail-quantity">
                                                        <h6>Količina <span>                                                                        <button className="dec" onClick={() => {
                                                            if (this.state.quantity - 1 >= product.MinOrder) {

                                                                this.setState({
                                                                    quantity: this.state.quantity - 1
                                                                })
                                                            }
                                                        }}></button>
                                                            {this.state.quantity}                                                                         <button className="inc" onClick={() => {
                                                                this.setState({
                                                                    quantity: this.state.quantity + 1
                                                                })

                                                            }}></button>
                                                        </span></h6>


                                                    </div>


                                                </div>
                                                    <button className="button" onClick={this.addToCart}>{this.props.translate('U KORPU')}</button>

                                            </div>
                                            : null}
                {
                    this.state._loading ?
                        <div className="loader">
                            <img src={logo} />
                            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div>
                        :
                        null
                }
            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(AppDetailPage));
