import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';
import { connect } from 'react-redux';

import HomeHeader from '../containers/header/homeHeader';
import Footer from '../containers/footer';

import slide from '../assets/images/slide.png';

import banner4 from '../assets/images/banner4.png';
import banner5 from '../assets/images/banner5.png';
import banner6 from '../assets/images/banner6.png';


import brands from '../assets/images/brands.png';
import article2 from '../assets/images/article2.png';
import article3 from '../assets/images/article3.png';
import article4 from '../assets/images/article4.png';
import article5 from '../assets/images/article5.png';
import article6 from '../assets/images/article6.png';
import article7 from '../assets/images/article7.png';
import article8 from '../assets/images/article8.png';
import category_banner from '../assets/images/category-banner.png';
import category_banner1 from '../assets/images/category-banner1.png';

import user from '../assets/images/user-review.png';

import blog1 from '../assets/images/blog1.png';
import blog2 from '../assets/images/blog2.png';
import blog3 from '../assets/images/blog3.png';


import Newsletter from '../components/newsletter';
import Article from '../components/article';
import BlogArticle from '../components/blogArticle1';
import HomeSlider from '../components/homeSlider';
import ReviewsSlider from '../components/reviewsSlider';
import Isvg from 'react-inlinesvg';

import badge1 from '../assets/svg/ico1.svg';
import badge2 from '../assets/svg/ico2.svg';
import badge3 from '../assets/svg/ico3.svg';
import rightArrow from '../assets/svg/right-arrow.svg';
import rightArrow1 from '../assets/svg/black-ra.svg';

import service1 from '../assets/images/service1.png';
import service2 from '../assets/images/service2.png';
import yellow_stars from '../assets/svg/yellow-stars.svg';
import Slider from "react-slick";

import brand1 from '../assets/images/brand1.png';
import brand2 from '../assets/images/brand2.png';
import brand3 from '../assets/images/brand3.png';
import brand4 from '../assets/images/brand4.png';
import Map from '../components/map';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';


class AppHomePage extends Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);

        this.state = {
            activeIndex: 0,
            banners: [
            ],
            verticalBanners: [

            ],
            slides: [

            ],
            categories: []

        };

    }



    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });


        this.props.socketIOClient.on('fetchSlides', (data) => {
            //console.log(data);
            this.setState({
                slides: data
            })
        });

        this.props.socketIOClient.on('fetchBanners', (data) => {
            //console.log(data);
            let vertical = [];
            let horizontal = [];
            for (let i = 0; i < data.length; i++) {
                if (data[i].vertical) {
                    vertical.push(data[i]);
                } else {
                    horizontal.push(data[i]);
                }
            }
            this.setState({
                banners: horizontal,
                verticalBanners: vertical
            })
        });


        this.props.socketIOClient.on('fetchHomeProducts', (data) => {
            //console.log(data);
            this.setState({
                categories: data
            })
        });

        this.props.socketIOClient.emit("fetchHomeProducts", {});

        this.props.socketIOClient.emit("fetchSlides", {});

        this.props.socketIOClient.emit("fetchBanners", {});

    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        //this.props.socketIOClient.removeAllListeners("fetchProduct");
        //this.props.socketIOClient.removeAllListeners("fetchProductVariations");

    }



    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: window.innerWidth < 768 ? 2 : 4,
            slidesToScroll: 1
        };

        //console.log(this.props.categories);
        return (
            <div className="home-wrap">

                <img src={brands} className="home-brands-image" />


                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <Isvg src={yellow_stars} />
                                <h2>Izdvojeno iz proizvoda</h2>
                                <p>Za Vas smo izdvojili posebnu ponudu proizvoda.</p>
                            </Col>

                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    {
                                        this.props.promotedProducts.map((item, idx) => {
                                            return (
                                                <Article
                                                    _id={item._id}
                                                    alias={item.Alias}
                                                    image={item.Images && item.Images.length && item.Images[0]}
                                                    title={item.Name}
                                                    discount={item.discount}
                                                    currency={this.props.currency}
                                                    package={item.package}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}
                                                    categoryName={item.categoryName}
                                                    lang={this.props.lang}

                                                >
                                                </Article>
                                            )
                                        })
                                    }

                                </Slider>

                            </Col>


                        </Row>
                    </Container>
                </section>

                <section className="section section-articles">
                    <Container>
                        <Row>
                            <Col lg="12" className="title">
                                <Isvg src={yellow_stars} />
                                <h2>Najnoviji proizvodi</h2>
                                <p>Novo u ponudi</p>
                            </Col>

                            <Col lg="12" className="slider-container">
                                <Slider {...settings}>
                                    {
                                        this.props.newestProducts.map((item, idx) => {
                                            return (
                                                <Article
                                                    _id={item._id}
                                                    alias={item.Alias}
                                                    image={item.Images && item.Images.length && item.Images[0]}
                                                    title={item.Name}
                                                    discount={item.discount}
                                                    currency={this.props.currency}
                                                    package={item.package}
                                                    price={item.price ? item.price : 0}
                                                    inStock={item.StockLevel > 0 ? true : false}
                                                    translate={this.props.translate}
                                                    categoryName={item.categoryName}
                                                    lang={this.props.lang}

                                                >
                                                </Article>
                                            )
                                        })
                                    }

                                </Slider>

                            </Col>

                            <Col lg="12" className="btn-wrap">
                                <Link to='/category'> <button className="button">Kompletan asortiman</button></Link>
                            </Col>


                        </Row>
                    </Container>
                </section>








            </div >
        );
    }
}



const mapStateToProps = state => ({
    menu: state.menu
});



export default connect(mapStateToProps)(PageWithLayout(AppHomePage));
