import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import image from '../assets/images/no-image.jpg';

export class Article extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        return (
            <Link to={`/product/${this.props.alias}/${this.props._id}`} className="article">
                <article >
                    <img onError={() => { this.setState({ imageError: true }) }} src={!this.state.imageError ? this.props.image ? this.props.image.scaleImage(200) : image : image} />
                    {this.props.discount ?
                        <div className="discount">-{this.props.discount}%</div>
                        :
                        null
                    }
                    <h6>{this.props.title && this.props.title}</h6>
                    <p>{this.props.shortDescription}</p>
                    {this.props.price ?
                        <p className="price">
                            <span className="price">{this.props.price.formatPrice(this.props.currency)} </span>


                        </p>

                        :                         <p className="price">
                        <span className="price">Cijena na upit </span>


                    </p>
}
                    {/*this.props.inStock ? <span className="in-stock">
                            {this.props.translate('Dostupno')}
                </span> : null*/}
                </article>
            </Link>

        )
    }
}

export default Article;