import React, { Component } from 'react';


const locations = [
    {
        coords: '44.77583, 17.18556'
    },
    {
        coords: '44.75874, 19.21437'
    },
    {
        coords: '44.87278, 18.80833'
    },
    {
        coords: '44.97991, 16.71401'
    },
    {
        coords: '43.84864, 18.35644'
    },
    {
        coords: '44.53842, 18.66709'
    },
    {
        coords: '44.8696158,17.6631705'
    },
    {
        coords: '44.20169, 17.90397'
    },
    {
        coords: '43.34333, 17.80806'
    },
    {
        coords: '44.97839, 17.90779'
    },
    {
        coords: '44.73179, 18.08698'
    },
    {
        coords: '43.50646, 18.77854'
    },
    {
        coords: '42.71197, 18.34362'
    },
    {
        coords: '44.34203, 17.27059'
    },
    {
        coords: '44.96694, 15.94306'
    },
    {
        coords: '44.05722, 17.45083'
    },

    {
        coords: '44.8155694,15.846168'
    },

    {
        coords: '44.7636231,16.6607358'
    },
    {
        coords: '43.8220674,16.9952455'
    },
    {
        coords: '44.3817508,19.0825974'
    },
    {
        coords: '44.6034853,17.8425854'
    },
    {
        coords: '44.225717,17.6363974'
    },
    {
        coords: '43.817512,18.5581128'
    },
	
	{
        coords: '44.698128,18.300276'
    },
	
	{
        coords: '44.447374,18.650133'
    },
	
	{
        coords: '44.877494,18.426738'
    },
	
	{
        coords: '43.830968,18.303677'
    },
	
	{
        coords: '44.981049,16.710657'
    },
	
	{
        coords: '43.826051,18.208503'
    },
	
	{
        coords: '44.907781,17.302123'
    },
	
	{
        coords: '44.544294,18.097619'
    },
	
	{
        coords: '44.182682,18.942973'
    },
	
	{
        coords: '45.139589,17.254825'
    },
	
	{
        coords: '44.154592,17.790994'
    },
	
	{
        coords: '43.991015,18.180117'
    },
	
	{
        coords: '43.899753,18.348468'
    },
	
	{
        coords: '44.439920,18.144660'
    },
	
	{
        coords: '44.128778,18.118753'
    },
	
	{
        coords: '43.649760,17.962064'
    },
	
	{
        coords: '44.613610,17.379020'
    },
	
	{
        coords: '45.183462,16.811189'
    },
	
	{
        coords: '44.652240,17.958896'
    },
	
	{
        coords: '45.095611,17.519895'
    },
	
	{
        coords: '44.022034,18.263615'
    },
	
	{
        coords: '44.161419,18.326205'
    },
	
	{
        coords: '44.483639,17.382919'
    },
	
	{
        coords: '44.956535,18.302202'
    },
	
	{
        coords: '44.695008,18.995642'
    },
	
	{
        coords: '43.798150,19.003945'
    },
	
	{
        coords: '44.125298,18.579575'
    },
	
	{
        coords: '44.184663,19.332106'
    },
	
	{
        coords: '44.496477,19.000271'
    },
	
	{
        coords: '43.668015,18.975443'
    },
	
	{
        coords: '43.943459,18.077442'
    },
	
	{
        coords: '43.872647, 18.323632'
    },
	
	{
        coords: '45.184246,15.806320'
    },
	
	{
        coords: '43.111373,17.699120'
    },
	
	{
        coords: '44.530176,18.529137'
    },
	
	{
        coords: '44.705662,18.491913'
    },
	
	{
        coords: '44.442522,18.876468'
    },
	
	{
        coords: '44.407089,18.526549'
    },
	
	{
        coords: '44.731000,18.176475'
    },
	
	{
        coords: '44.225335,18.694074'
    },
	
	{
        coords: '43.823866,18.355592'
    },
	
	{
        coords: '43.948683,18.266364'
    },
	
	{
        coords: '45.011716,18.327375'
    },
	
	{
        coords: '44.429146,18.037212'
    },


]

export class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    initMap() {
        //console.log("InitMAP");
        this.setState({
            _mapInit: true
        });
        var latLng = new window.google.maps.LatLng(43.9, 17.7721895);

        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 7.9,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",
        });


        for (let i = 0; i < locations.length; i++)
            var marker = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(locations[i].coords.split(',')[0], locations[i].coords.split(',')[1]),
                map: map,
            });


        this.setState({ googleMap: map });
    }

    componentDidMount() {

        if (this.props._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }

    }

    componentDidUpdate(prevProps) {

        if (this.props._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }
    }


    render() {
        return (
            this.props._googleMapsLoaded ?
                <div className="map locations-map" ref={(input) => { this.GoogleMap = input; }}>

                </div>
                : null
        )
    }
}

export default Map;